import { useOidc, useOidcIdToken } from '@axa-fr/react-oidc'
import {
    CircularProgress,
    CssBaseline,
    makeStyles,
    Typography
} from '@material-ui/core'
import React, { ReactNode, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { acceptInvitation } from '../../apis/admin'
import localStorage from '../../helpers/localStore'

type Props = {
    children?: ReactNode
}

const INVITATION_TOKEN = 'invitationToken'

const getQueryParams = () => {
    let urlParams
    // The invitation system has a bug where it doesn't dencode the invitation before
    // redirecting to the app. This is a workaround to decode the invitation before
    if (window.location.search.includes('invitationVersion%3D1')) {
        urlParams = new URLSearchParams(decodeURIComponent(window.location.search))
    } else {
        urlParams = new URLSearchParams(window.location.search)
    }

    return new URLSearchParams(urlParams)
}

const useStyles = makeStyles(theme => ({
    waitingBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh'
    },
    waitingText: {
        marginLeft: theme.spacing(2)
    }
}))

enum CurrentRenderStateEnum {
    normal,
    acceptingInvitation
}

const InvitationProvider = ({ children }: Props) => {
    const { idTokenPayload } = useOidcIdToken()
    const params = getQueryParams()
    const invitationVersion = params.get('invitationVersion')
    const invitationIv = params.get('invitationIv')
    const invitation = params.get('invitation')
    const classes = useStyles()
    const { login } = useOidc()
    const intl = useIntl()
    const { search, pathname } = useLocation()
    const [currentRenderState, setCurrentRenderState] = useState<
        CurrentRenderStateEnum
    >(CurrentRenderStateEnum.normal)

    if (invitationVersion && invitationIv && invitation) {
        localStorage.set(INVITATION_TOKEN, {
            invitationVersion,
            invitationIv,
            invitation
        })
        window.history.replaceState({}, document.title, window.location.pathname)
    }

    const invitationStored = localStorage.get(INVITATION_TOKEN)
    if (idTokenPayload && invitationStored?.invitation) {
        const { invitationVersion, invitationIv, invitation } = invitationStored
        if (invitationVersion && invitationIv && invitation) {
            setCurrentRenderState(CurrentRenderStateEnum.acceptingInvitation)
            localStorage.set(INVITATION_TOKEN, null)
            acceptInvitation(invitationVersion, invitationIv, invitation).finally(
                () => {
                    login(search?.length > 1 ? pathname + search : pathname, { noTenant: 'true' })
                }
            )
        }
    }

    if (currentRenderState === CurrentRenderStateEnum.acceptingInvitation) {
        return (
            <>
                <CssBaseline />
                <div className={classes.waitingBox}>
                    <CircularProgress />
                    <Typography variant="h5">
                        <span className={classes.waitingText}>
                            {intl.formatMessage({ id: 'screens.invitationScreen.waiting' })}
                        </span>
                    </Typography>
                </div>
            </>
        )
    } else {
        return <>{children}</>
    }
}

export default InvitationProvider
