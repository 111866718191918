import React from 'react'
import PropTypes from 'prop-types'
import MikeSnackbar from '@mike/mike-shared-frontend/mike-snackbar'
import { Route, Switch } from 'react-router-dom'
import { useOidc, useOidcIdToken } from '@axa-fr/react-oidc'

import { getNotAuthErrors } from '../../helpers/errors'
import AppPropTypes from '../../shared/appPropTypes'
import AppLayout from '../AppLayout'
import PageNotFound from '../PageNotFound'
import APIAccess from '../APIAccess'
import UserGroups from '../UserGroups'
import ConnectedProjectContent from '../../containers/ConnectedProjectContent'
import ConnectedRecycleBinItemsDeletedByMe from '../../containers/ConnectedRecycleBinItemsDeletedByMe'
import Users from '../Users'
import AppNotificationSnack from '../AppNotificationSnack'
import LinkNotificationSnack from '../LinkNotificationSnack'
import { instance as appInsightsInstance } from '../../apis/appInsights'
import { CATEGORIES } from '../../helpers/project'
import { Typography } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { setUser, setIsAuthenticated } from '../../actions/auth'
import { getUserProfile } from './../../apis/support'
import { useDispatch } from 'react-redux'
import LandingPage from '../LandingPage'
import PrivateRoute from '../PrivateRoute'
import RedirectRoute from '../RedirectRoute'
import SiteDetails from '../SiteDetails'
import SiteCpuUsage from '../SiteCpuUsage'
import CreditsSummary from '../Credits/CreditsPanels'
import SiteStorageUsage from '../SiteStorageUsage'
import Applications from '../Applications'
import RootProject from '../RootProject'
import { useLocation } from 'react-router-dom'
import qs from 'qs'
import { useTypedSelector } from '../../reducers'
import CreditsAllocation from '../Credits/CreditsPanels/CreditsAllocation'
import CreditsAvailable from '../Credits/CreditsPanels/CreditsAvailable'
import CreditsSpent from '../Credits/CreditsPanels/CreditsSpent'
import CreditsReserved from '../Credits/CreditsPanels/CreditsReserved'
export const DEFAULT_PROJECTS_VIEW = CATEGORIES.MY

const App = ({
    errors,
    notification,
    linkNotification,
    linkToProject,
    loginConfig,
    support,
    tutorial,
    onCloseError,
    onCloseLinkNotification,
    onClosePopupReminder,
    onSurpressPopupReminder,
    appNotifications,
    popupReminderIsOpen,
    surpressPopupReminder,
    progressItems,
    loadingTenants,
    tenants,
    openCreateTenant,
    onCreateTenantCloseClick,
    onCreateTenant,
    onOpenCreateTenant,
    isAdmin,
    isBackOffice,
    features,
    currentProject
    // pca
}) => {
    const dispatch = useDispatch()
    const otherErrors = getNotAuthErrors(errors)
    const onLogoutClick = React.useCallback(() => {
        appInsightsInstance().setAuthenticatedUserContext(null)
    }, [])

    const [lastOtherError, setLastOtherError] = React.useState(null)
    const { isAuthenticated, login } = useOidc()
    const { idToken, idTokenPayload } = useOidcIdToken()

    const { search, pathname } = useLocation()

    const user = useTypedSelector(state => state.auth.user)

    const [currentUser, setCurrentUser] = React.useState(null)

    React.useEffect(() => {
        if (idToken && idTokenPayload) {
            const user = getUserProfile(idTokenPayload)
            dispatch(setUser(user))
            setCurrentUser(user)
        }
    }, [dispatch, idToken, idTokenPayload])

    React.useEffect(() => {
        if (user) {
            if (search) {
                const pathAndSearch = search.length > 1 ? pathname + search : pathname
                const parameters = qs.parse(search.replace('?', '')) || {}
                if (parameters.tenantId && parameters.tenantId !== user.tenantId) {
                    const extras = {
                        prompt: 'none',
                        tenantId: parameters.tenantId.toString()
                    }
                    login(pathAndSearch, extras)
                }
            }
        } else {
            const pathAndSearch = search.length > 1 ? pathname + search : pathname
            if (search.includes('invitationVersion')) {
                const extras = {
                    noTenant: 'true',
                }
                login(pathAndSearch, extras)
            }
        }
    }, [user, login, search, pathname])

    React.useEffect(() => {
        if (user) {
            const _paq = (window._paq = window._paq || [])
            _paq.push(['setUserId', user.email])
            _paq.push(['trackPageView'])
            _paq.push(['enableHeartBeatTimer'])
        }
    }, [user])

    React.useEffect(() => {
        if (isAuthenticated) {
            dispatch(setIsAuthenticated(isAuthenticated))
        }
    }, [dispatch, isAuthenticated, isBackOffice])

    React.useEffect(() => {
        const count = otherErrors ? otherErrors.length : 0
        setLastOtherError(count > 0 ? otherErrors[count - 1] : null)
    }, [otherErrors])

    const onRemoveError = React.useCallback(() => {
        lastOtherError && onCloseError(lastOtherError.id)
    }, [lastOtherError, onCloseError])

    const handleSurpressReminder = event => {
        const checked = event.target.checked
        onSurpressPopupReminder(checked)
    }

    if (!isAuthenticated) {
        return <LandingPage />
    }

    return (
        <>
            <AppNotificationSnack open={!!notification} message={notification} />

            <MikeSnackbar
                open={!!lastOtherError}
                mikeSnackbarType={'error'}
                message={lastOtherError && lastOtherError.message}
                action={'OK'}
                mikeOnAction={onRemoveError}
                onClose={onRemoveError}
                autoHideDuration={null}
            />

            <MikeSnackbar
                open={popupReminderIsOpen}
                mikeSnackbarType={'regular'}
                action={'OK'}
                mikeOnAction={onClosePopupReminder}
                onClose={onClosePopupReminder}
                autoHideDuration={null}
            >
                <Typography variant="body1">
                    {
                        'Turn off the pop-up blocker in your browser to allow download of files'
                    }
                </Typography>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={surpressPopupReminder}
                            onChange={handleSurpressReminder}
                            name={'surpressReminder'}
                        />
                    }
                    label={'Do not show this again'}
                />
            </MikeSnackbar>
            <>
                <LinkNotificationSnack
                    message={linkNotification}
                    onCloseCallBack={onCloseLinkNotification}
                    open={!!linkToProject}
                    project={linkToProject}
                />

                <AppLayout
                    user={currentUser}
                    onLogoutClick={onLogoutClick}
                    support={support}
                    tutorial={tutorial}
                    loginConfig={loginConfig}
                    appNotifications={appNotifications}
                    progressItems={progressItems}
                    loadingTenants={loadingTenants}
                    tenants={tenants}
                    openCreateTenant={openCreateTenant}
                    onCreateTenantCloseClick={onCreateTenantCloseClick}
                    onCreateTenant={onCreateTenant}
                    onOpenCreateTenant={onOpenCreateTenant}
                    isAdmin={isAdmin}
                    isBackOffice={isBackOffice}
                    features={features}
                    currentProject={currentProject}
                >
                    <Switch>
                        <Route path="/site/:id" component={ConnectedProjectContent} />
                        <Route path="/project/:id" component={ConnectedProjectContent} />
                        <Route path="/site" component={RootProject} />
                        <Route path="/project" component={RootProject} />
                        <Route
                            path="/recycle-bin/:id"
                            component={ConnectedRecycleBinItemsDeletedByMe}
                        />
                        <PrivateRoute
                            isSignedIn={isAdmin}
                            path="/site-access/users"
                            component={Users}
                        />
                        <PrivateRoute
                            isSignedIn={isAdmin}
                            path="/site-access/groups"
                            component={UserGroups}
                        />
                        <PrivateRoute
                            isSignedIn={isAdmin}
                            path="/site-access/api"
                            component={APIAccess}
                        />
                        <PrivateRoute
                            isSignedIn={isAdmin}
                            path="/site-access/applications"
                            component={Applications}
                        />
                        <RedirectRoute path="/site-access" to="/site-access/users" />

                        <PrivateRoute
                            isSignedIn={isAdmin}
                            path="/site-info/details"
                            component={SiteDetails}
                        />
                        <PrivateRoute
                            isSignedIn={isAdmin}
                            path="/site-info/cpu"
                            component={SiteCpuUsage}
                        />
                        <PrivateRoute
                            isSignedIn={isAdmin}
                            path="/site-info/storage"
                            component={SiteStorageUsage}
                        />
                        <PrivateRoute
                            isSignedIn={isAdmin}
                            path="/site-info/credits"
                            component={CreditsSummary}
                        />

                        <PrivateRoute
                            isSignedIn={isAdmin}
                            path="/site-info/credits-allocation"
                            component={CreditsAllocation}
                        />
                        <PrivateRoute
                            isSignedIn={isAdmin}
                            path="/site-info/credits-available"
                            component={CreditsAvailable}
                        />
                        <PrivateRoute
                            isSignedIn={isAdmin}
                            path="/site-info/credits-reserved"
                            component={CreditsReserved}
                        />
                        <PrivateRoute
                            isSignedIn={isAdmin}
                            path="/site-info/credits-spent"
                            component={CreditsSpent}
                        />
                        <RedirectRoute path="/site-info" to="/site-info/details" />

                        <RedirectRoute exact path="/" to="/site" />
                        <Route component={PageNotFound} />
                    </Switch>
                </AppLayout>
            </>
        </>
    )
}
App.propTypes = {
    errors: PropTypes.arrayOf(AppPropTypes.error).isRequired,
    linkNotification: PropTypes.string,
    notification: PropTypes.string,
    linkToProject: AppPropTypes.project,
    loginConfig: AppPropTypes.loginConfig.isRequired,
    support: AppPropTypes.support.isRequired,
    tutorial: AppPropTypes.tutorial.isRequired,
    onCloseError: PropTypes.func,
    onCloseLinkNotification: PropTypes.func,
    onClosePopupReminder: PropTypes.func.isRequired,
    onSurpressPopupReminder: PropTypes.func.isRequired,
    onDismissProgressListItem: PropTypes.func.isRequired,
    appNotifications: PropTypes.array.isRequired,
    appStatus: PropTypes.object,
    popupReminderIsOpen: PropTypes.bool.isRequired,
    surpressPopupReminder: PropTypes.bool.isRequired,
    progressItems: PropTypes.array,
    loadingTenants: PropTypes.bool.isRequired,
    tenants: PropTypes.array,
    openCreateTenant: PropTypes.bool,
    onCreateTenantCloseClick: PropTypes.func.isRequired,
    onCreateTenant: PropTypes.func.isRequired,
    onOpenCreateTenant: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool,
    isBackOffice: PropTypes.bool,
    features: PropTypes.array,
    currentProject: PropTypes.object
}

export default App
